var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "mb-0",
    attrs: {
      "id": "filters-tour",
      "body-class": "px-1 py-0",
      "header-class": "py-1"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 d-flex-between cursor-pointer",
          on: {
            "click": _vm.handleToggleShow
          }
        }, [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-2"
        }, [_c('IAmIcon', {
          staticClass: "text-success",
          attrs: {
            "icon": "eye",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "fw-600 text-nowrap"
        }, [_vm._v(_vm._s(_vm.$t('tour.displaySettings')))])], 1), _c('div', {
          staticClass: "d-flex-center"
        }, [_c('IAmIcon', {
          staticClass: "rotate_class text-secondary cursor-pointer",
          style: {
            transform: _vm.rotateStyleVisible,
            width: '12px'
          },
          attrs: {
            "icon": "chevronDownOutline",
            "size": "18"
          }
        })], 1)])];
      },
      proxy: true
    }])
  }, [_c('b-collapse', {
    attrs: {
      "tag": "div"
    },
    model: {
      value: _vm.isVisible,
      callback: function callback($$v) {
        _vm.isVisible = $$v;
      },
      expression: "isVisible"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column gap-2 pb-1"
  }, [_c('div', {
    staticClass: "d-flex flex-column gap-2"
  }, [_c('div', {
    staticClass: "d-flex gap-3 "
  }, [_c('BFormCheckbox', {
    staticClass: "success-switch",
    attrs: {
      "switch": "",
      "checked": _vm.isShowCommission
    },
    on: {
      "change": function change(val) {
        return _vm.updateShowCommission(val);
      }
    }
  }, [_c('span', {
    staticClass: "cursor-pointer"
  }, [_vm._v("Hoa hồng")])])], 1)])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }