import { STATUS_TOUR } from '@/constants/tour'

export const tourTypes = {
  OUTBOUND: 'OUTBOUND',
  DOMESTIC: 'DOMESTIC',
}

export const defaultDepartPoint = 'TP. Hồ Chí Minh'

export const VEHICLE_TYPES = [
  'CUSTOM',
  'AIRPLANE',
  'CAR',
  'TRAIN',
  'HIGH_SPEED_TRAIN',
]

// func check xem có phải status yêu cầu thanh toán hay ko (Yêu cầu đặt cọc nhưng full tiền)
export const checkStatusRequestPaymentFull = booking => {
  if (booking?.status && (booking?.status !== STATUS_TOUR.REQUEST_PAYMENT)) return false
  return booking?.requestPaymentAmount && (booking.requestPaymentAmount === ((booking?.totalPriceToBePaid || 0) - (booking?.totalAmountPaid || 0)))
}
