var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HStack', [_vm._l(_vm.paths, function (path, pathIndex) {
    return [_c('span', {
      key: pathIndex,
      class: "text-nowrap ".concat(path.current ? 'text-tour fw-700' : 'text-dark fw-600', " text-truncate"),
      style: "cursor: pointer; max-width: ".concat(_vm.isMobileView ? '250px' : 'unset'),
      on: {
        "click": function click($event) {
          return _vm.onClickHandle(path);
        }
      }
    }, [_vm._v(" " + _vm._s(path === null || path === void 0 ? void 0 : path.name) + " ")]), !(path !== null && path !== void 0 && path.current) ? _c('span', {
      key: "splash-".concat(pathIndex)
    }, [_vm._v("/")]) : _vm._e()];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }