<template lang="">
  <HStack>
    <template v-for="(path, pathIndex) in paths">
      <span
        :key="pathIndex"
        :class="`text-nowrap ${path.current ? 'text-tour fw-700' : 'text-dark fw-600'} text-truncate`"
        :style="`cursor: pointer; max-width: ${isMobileView ? '250px' : 'unset'}`"
        @click="onClickHandle(path)"
      >
        {{ path?.name }}
      </span>
      <span
        v-if="!path?.current"
        :key="`splash-${pathIndex}`"
      >/</span>
    </template>
  </HStack>
</template>
<script>
import router from '@/router'

export default {
  props: {
    paths: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const onClickHandle = path => {
      if (path.current) return
      router.push(path.to)
    }
    return {
      onClickHandle,
    }
  },
}
</script>
<style lang="">

</style>
