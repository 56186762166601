import { onMounted, ref } from '@vue/composition-api'

import store from '@/store'
import router from '@/router'

import useToast from '@useToast'

import tourStoreModule from '../tourStoreModule'

export default function useTourDetailHandle() {
  if (!store.hasModule('app-tour')) {
    store.registerModule('app-tour', tourStoreModule) // NOTE: register store khi import file js này, đến hiện tại thì vẫn oke
  }
  const { toastError } = useToast()

  const tourSelected = ref()

  const loadingDetail = ref(false)

  const getTourRowDetail = rowId => new Promise((resolve, reject) => {
    const getTourSelected = store.getters['app-tour/getTourSelected']
    if (getTourSelected && getTourSelected.priceId === rowId) {
      tourSelected.value = getTourSelected
      resolve(getTourSelected)
      return
    }
    loadingDetail.value = true
    store
      .dispatch('app-tour/getTourRowDetail', rowId)
      .then(res => {
        if (!res) {
          toastError('tour.msg.selectedTourNotFound')
          router.replace({ name: 'apps-toursList' })
          return
        }
        tourSelected.value = res
        resolve(res.data)
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
        reject(e)
      })
      .finally(() => {
        loadingDetail.value = false
      })
  })

  onMounted(() => {
    if (!tourSelected.value) {
      getTourRowDetail(router.currentRoute.params.id)
    }
  })

  return {
    getTourRowDetail,
    tourSelected,
    loadingDetail,
  }
}

export function useTourCalendarHandle() {
  if (!store.hasModule('app-tour')) {
    store.registerModule('app-tour', tourStoreModule) // NOTE: register store khi import file js này, đến hiện tại thì vẫn oke
  }
  const { toastError } = useToast()

  const loadingCalendar = ref(false)
  const getTourCalendar = (tourId, params) => new Promise((resolve, reject) => {
    loadingCalendar.value = true
    store
      .dispatch('app-tour/getTourCalendar', { tourId, params })
      .then(res => {
        resolve(res.data)
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
        reject(e)
      })
      .finally(() => {
        loadingCalendar.value = false
      })
  })

  return {
    getTourCalendar,
    loadingCalendar,
  }
}
