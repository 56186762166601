<template>
  <b-card
    id="filters-tour"
    body-class="px-1 py-0"
    header-class="py-1"
    class="mb-0"
  >
    <template #header>
      <div
        class="w-100 d-flex-between cursor-pointer"
        @click="handleToggleShow"
      >
        <div class="d-flex-center justify-content-start gap-2">
          <IAmIcon
            icon="eye"
            size="18"
            class="text-success"
          />
          <span class="fw-600 text-nowrap">{{ $t('tour.displaySettings') }}</span>
        </div>

        <div class="d-flex-center">
          <IAmIcon
            icon="chevronDownOutline"
            size="18"
            :style="{ transform: rotateStyleVisible, width: '12px' }"
            class="rotate_class text-secondary cursor-pointer"
          />
        </div>
      </div>
    </template>

    <!-- SECTION collapse Filter -->
    <b-collapse
      v-model="isVisible"
      tag="div"
    >
      <div class="d-flex flex-column gap-2 pb-1">
        <!-- ANCHOR: Tour type -->
        <div class="d-flex flex-column gap-2">
          <div class="d-flex gap-3 ">
            <BFormCheckbox
              switch
              :checked="isShowCommission"
              class="success-switch"
              @change="val => updateShowCommission(val)"
            >
              <span class="cursor-pointer">Hoa hồng</span>
            </BFormCheckbox>
          </div>
        </div>
      </div>
    </b-collapse>
    <!-- !SECTION -->
  </b-card>
</template>

<script>
import { BCard, BCollapse, BFormCheckbox } from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'

import store from '@/store'

export default {
  components: {
    BCard,
    BCollapse,
    BFormCheckbox,
  },
  setup() {
    const isVisible = ref(true)
    const rotateStyleVisible = computed(() => isVisible.value ? 'rotate(0deg)' : 'rotate(-180deg)')
    const handleToggleShow = () => {
      isVisible.value = !isVisible.value
    }
    const updateShowCommission = val => {
      store.dispatch('app-tour/setShowCommission', val)
    }

    const isShowCommission = computed(() => store.getters['app-tour/getShowCommission'])

    return {
      isVisible,
      rotateStyleVisible,
      handleToggleShow,
      updateShowCommission,
      isShowCommission,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.rotate_class {
  transition: transform 0.3s ease;
}

.success-switch ::v-deep {
  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before
  {
    background-color: #28A745 !important;
    border-color: #28A745 !important;
  }
}

</style>
