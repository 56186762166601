import { apiTour } from '@/api'
import router from '@/router'
import { STATUS_TOUR } from '@/constants/tour'

import { checkStatusRequestPaymentFull } from './useTourHandle'

export default {
  namespaced: true,
  state: {
    loading: false,
    tourSelected: null,
    bookingData: null,
    showCommission: true,
  },
  getters: {
    getLoading: state => state.loading,
    getTourSelected: state => state.tourSelected,
    getBookingData: state => state.bookingData,
    getShowCommission: state => state.showCommission,
  },
  mutations: {
    SET_TOUR_SELECTED(state, value) {
      state.tourSelected = value
    },
    SET_LOADING(state, value) {
      state.loading = value
    },

    // Show commission
    SET_SHOW_COMMISSION(state, value) {
      state.showCommission = value
    },

    // ANCHOR BOOKING DETAIL
    SET_BOOKING_DATA(state, val) {
      state.bookingData = val
    },
  },
  actions: {
    // BOOK
    setTourSelected({ commit }, tourSelected) {
      commit('SET_TOUR_SELECTED', tourSelected)
    },
    clearTourSelected({ commit }) {
      commit('SET_TOUR_SELECTED', null)
    },
    getTourRow(_ctx, params) {
      return apiTour.getTourRow(params)
    },
    async getTourRowDetail({ commit }, rowId) {
      const res = await apiTour.getTourRowDetail(rowId)
      if (!res?.data) return null
      commit('SET_TOUR_SELECTED', res.data)
      return res.data
    },
    getTourCategory(_ctx, params) {
      return apiTour.getTourCategory(params)
    },

    getTourCalendar(_ctx, { tourId, params }) {
      return apiTour.getTourCalendar(tourId, params)
    },

    checkSeatAvailable(_ctx, priceId) {
      return apiTour.checkSeatAvailable(priceId)
    },

    createTourBooking(_ctx, payload) {
      return apiTour.createTourBooking(payload)
    },

    setShowCommission({ commit }, value) {
      commit('SET_SHOW_COMMISSION', value)
    },

    // BOOKING
    getBookingById({ commit }, id = undefined) {
      const idParams = id || router?.currentRoute.params.id
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        commit('SET_BOOKING_DATA', null)

        apiTour.getBooking(idParams)
          .then(res => {
            res.data.realStatus = checkStatusRequestPaymentFull(res.data) ? STATUS_TOUR.REQUEST_PAYMENT_FULL : res.data.status
            commit('SET_BOOKING_DATA', res.data)
            resolve(res.data)
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            commit('SET_LOADING', false)
          })
      })
    },
  },
}
