var render = function () {
  var _vm$tourSelected, _vm$tourSelected2, _vm$tourSelected4, _vm$tourSelected$adul, _vm$tourSelected5, _vm$tourSelected6, _vm$tourSelected$chil, _vm$tourSelected7, _vm$tourSelected8, _vm$tourSelected$chil2, _vm$tourSelected9, _vm$tourSelected10, _vm$tourSelected$infa, _vm$tourSelected11;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    staticClass: "rounded-8px mb-0 h-100 d-flex-center",
    attrs: {
      "body-class": "fit-content"
    }
  }, [_c('Stack', {
    staticClass: "gap-2",
    attrs: {
      "center": ""
    }
  }, [_c('div', {
    class: "text-center text-tour ".concat(_vm.isMobileView ? 'text-16px' : 'text-24px', " fw-700")
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('div', {
    class: "fw-700 text-center ".concat(_vm.isMobileView ? 'text-14px' : 'text-20px')
  }, [_vm._v(" " + _vm._s((_vm$tourSelected = _vm.tourSelected) === null || _vm$tourSelected === void 0 ? void 0 : _vm$tourSelected.name) + " ")]), _c('Stack', {
    staticClass: "w-100",
    attrs: {
      "center": ""
    }
  }, [_c('div', {
    class: "d-flex-between gap-2 w-100 ".concat(_vm.isMobileView ? 'flex-column' : 'flex-row')
  }, _vm._l((_vm$tourSelected2 = _vm.tourSelected) === null || _vm$tourSelected2 === void 0 ? void 0 : _vm$tourSelected2.itineraries, function (itinerary, itineraryIndex) {
    return _c('div', {
      key: itineraryIndex,
      class: "d-flex-between gap-2 ".concat(_vm.isMobileView ? 'flex-column' : 'flex-row')
    }, [_vm._l(itinerary, function (segment, segmentIndex) {
      var _vm$tourSelected3;
      return _c('TourShortenFlightTrip', {
        key: segmentIndex,
        attrs: {
          "segment": segment,
          "type": "block",
          "date-type": itineraryIndex === ((_vm$tourSelected3 = _vm.tourSelected) === null || _vm$tourSelected3 === void 0 ? void 0 : _vm$tourSelected3.itineraries.length) - 1 ? 'arrival' : 'departure'
        }
      });
    }), itineraryIndex === 0 && !_vm.isMobileView ? _c('div', {
      staticClass: "divider-line"
    }) : _vm._e()], 2);
  }), 0), _vm.showInfo ? _c('div', {
    staticClass: "top-border"
  }) : _vm._e(), _vm.showInfo ? _c('TourDetailInfo', {
    attrs: {
      "tourSelected": _vm.tourSelected,
      "customClass": "".concat(_vm.isMobileView ? 'd-flex flex-column gap-1' : 'd-grid grid-cols-2 gap-2', " my-50 w-100")
    }
  }) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "top-border"
  }), _c('Stack', {
    staticClass: "gap-2"
  }, [_c('HStack', {
    attrs: {
      "between": _vm.isMobileView,
      "center": !_vm.isMobileView
    }
  }, [_vm.isMobileView ? _c('div') : _vm._e(), _c('div', {
    class: "text-center text-tour ".concat(_vm.isMobileView ? 'text-16px' : 'text-24px', " fw-700")
  }, [_vm._v(" Giá tour ")]), _vm.isMobileView ? _c('BButton', {
    staticClass: "p-50 rounded-circle",
    attrs: {
      "variant": "flat-success"
    },
    on: {
      "click": _vm.toggleShowCommission
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": _vm.isShowCommission ? 'moneyOutline' : 'notMoneyOutline',
      "size": "18"
    }
  })], 1) : _vm._e()], 1), _c('div', {
    class: "d-flex-between gap-2 ".concat(_vm.isMobileView ? 'flex-column' : 'flex-row')
  }, [_c('Stack', {
    staticClass: "flex-1 gap-2 w-100"
  }, [_c('div', {
    staticClass: "d-flex-between gap-2"
  }, [_c('Stack', {
    staticClass: "py-50 gap-0"
  }, [_c('div', {
    staticClass: "fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('tour.passenger.Adults')) + ": ")]), _c('span', {
    staticClass: "font-italic text-12px",
    staticStyle: {
      "font-weight": "400"
    }
  }, [_vm._v(" (" + _vm._s(_vm.$t('tour.passenger.ageFrom', {
    ageFrom: _vm.tourSelected.agesAdults
  })) + ") ")])]), _c('Stack', {
    attrs: {
      "end": "",
      "gap": "0"
    }
  }, [_c('div', {
    class: "text-danger ".concat(_vm.isMobileView ? 'text-16px' : 'text-20px', " fw-700")
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_vm$tourSelected4 = _vm.tourSelected) === null || _vm$tourSelected4 === void 0 ? void 0 : _vm$tourSelected4.adultsPriceSales)) + " ")]), _vm.isShowCommission ? _c('span', {
    staticClass: "font-italic text-12px"
  }, [_vm._v(" " + _vm._s(_vm.$t('tour.field.commission')) + ": "), _c('span', {
    staticClass: "fw-600 text-success"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_vm$tourSelected$adul = (_vm$tourSelected5 = _vm.tourSelected) === null || _vm$tourSelected5 === void 0 ? void 0 : _vm$tourSelected5.adultsAgencyPromotion) !== null && _vm$tourSelected$adul !== void 0 ? _vm$tourSelected$adul : 0)) + " ")])]) : _vm._e()])], 1), _c('div', {
    staticClass: "d-flex-between gap-2"
  }, [_c('Stack', {
    staticClass: "py-50 gap-0"
  }, [_c('div', {
    staticClass: "fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('tour.passenger.Children1')) + ": ")]), _c('span', {
    staticClass: "font-italic text-12px",
    staticStyle: {
      "font-weight": "400"
    }
  }, [_vm._v(" (" + _vm._s(_vm.$t('tour.passenger.ageBetween', {
    ageFrom: _vm.tourSelected.agesChildren1From,
    ageTo: _vm.tourSelected.agesChildren1To
  })) + ") ")])]), _c('Stack', {
    attrs: {
      "end": "",
      "gap": "0"
    }
  }, [_c('div', {
    class: "text-danger ".concat(_vm.isMobileView ? 'text-16px' : 'text-20px', " fw-700")
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_vm$tourSelected6 = _vm.tourSelected) === null || _vm$tourSelected6 === void 0 ? void 0 : _vm$tourSelected6.childrenHasBedPriceSales)) + " ")]), _vm.isShowCommission ? _c('span', {
    staticClass: "font-italic text-12px"
  }, [_vm._v(" " + _vm._s(_vm.$t('tour.field.commission')) + ": "), _c('span', {
    staticClass: "fw-600 text-success"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_vm$tourSelected$chil = (_vm$tourSelected7 = _vm.tourSelected) === null || _vm$tourSelected7 === void 0 ? void 0 : _vm$tourSelected7.children1AgencyPromotion) !== null && _vm$tourSelected$chil !== void 0 ? _vm$tourSelected$chil : 0)) + " ")])]) : _vm._e()])], 1)]), !_vm.isMobileView ? _c('div', {
    staticClass: "divider-line"
  }) : _vm._e(), _c('Stack', {
    staticClass: "flex-1 gap-2 w-100"
  }, [_c('div', {
    staticClass: "d-flex-between gap-2"
  }, [_c('Stack', {
    staticClass: "py-50 gap-0"
  }, [_c('div', {
    staticClass: "fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('tour.passenger.Children2')) + ": ")]), _c('span', {
    staticClass: "font-italic text-12px",
    staticStyle: {
      "font-weight": "400"
    }
  }, [_vm._v(" (" + _vm._s(_vm.$t('tour.passenger.ageBetween', {
    ageFrom: _vm.tourSelected.agesChildren2From || _vm.tourSelected.agesChildren1From,
    ageTo: _vm.tourSelected.agesChildren2To || _vm.tourSelected.agesChildren1To
  })) + ") ")])]), _c('Stack', {
    attrs: {
      "end": "",
      "gap": "0"
    }
  }, [_c('div', {
    class: "text-danger ".concat(_vm.isMobileView ? 'text-16px' : 'text-20px', " fw-700")
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_vm$tourSelected8 = _vm.tourSelected) === null || _vm$tourSelected8 === void 0 ? void 0 : _vm$tourSelected8.childrenNoBedPriceSales)) + " ")]), _vm.isShowCommission ? _c('span', {
    staticClass: "font-italic text-12px"
  }, [_vm._v(" " + _vm._s(_vm.$t('tour.field.commission')) + ": "), _c('span', {
    staticClass: "fw-600 text-success"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_vm$tourSelected$chil2 = (_vm$tourSelected9 = _vm.tourSelected) === null || _vm$tourSelected9 === void 0 ? void 0 : _vm$tourSelected9.children2AgencyPromotion) !== null && _vm$tourSelected$chil2 !== void 0 ? _vm$tourSelected$chil2 : 0)) + " ")])]) : _vm._e()])], 1), _c('div', {
    staticClass: "d-flex-between gap-2"
  }, [_c('Stack', {
    staticClass: "py-50 gap-0"
  }, [_c('div', {
    staticClass: "fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('tour.passenger.Infant')) + ": ")]), _c('span', {
    staticClass: "font-italic text-12px",
    staticStyle: {
      "font-weight": "400"
    }
  }, [_vm._v(" (" + _vm._s(_vm.$t('tour.passenger.ageTo', {
    ageTo: _vm.tourSelected.agesInfant
  })) + ") ")])]), _c('Stack', {
    attrs: {
      "end": "",
      "gap": "0"
    }
  }, [_c('div', {
    class: "text-danger ".concat(_vm.isMobileView ? 'text-16px' : 'text-20px', " fw-700")
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_vm$tourSelected10 = _vm.tourSelected) === null || _vm$tourSelected10 === void 0 ? void 0 : _vm$tourSelected10.infantPriceSales)) + " ")]), _vm.isShowCommission ? _c('span', {
    staticClass: "font-italic text-12px"
  }, [_vm._v(" " + _vm._s(_vm.$t('tour.field.commission')) + ": "), _c('span', {
    staticClass: "fw-600 text-success"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_vm$tourSelected$infa = (_vm$tourSelected11 = _vm.tourSelected) === null || _vm$tourSelected11 === void 0 ? void 0 : _vm$tourSelected11.infantAgencyPromotion) !== null && _vm$tourSelected$infa !== void 0 ? _vm$tourSelected$infa : 0)) + " ")])]) : _vm._e()])], 1)])], 1)], 1), _c('div', {
    staticClass: "d-flex-center mt-1"
  }, [_c('BAlert', {
    staticClass: "px-1 py-50 fit-content rounded-pill fw-600 mb-0 text-center",
    attrs: {
      "variant": "success",
      "show": ""
    }
  }, [_vm._v(" Chưa bao gồm tiền phí dịch vụ 100.000 vnđ/ ngày/ khách. ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }