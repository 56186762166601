<template lang="">
  <BCard
    class="rounded-8px mb-0 h-100 d-flex-center"
    body-class="fit-content"
  >
    <Stack
      center
      class="gap-2"
    >
      <div :class="`text-center text-tour ${isMobileView ? 'text-16px' : 'text-24px'} fw-700`">
        {{ title }}
      </div>
      <div :class="`fw-700 text-center ${isMobileView ? 'text-14px' : 'text-20px'}`">
        {{ tourSelected?.name }}
      </div>
      <Stack
        center
        class="w-100"
      >
        <div :class="`d-flex-between gap-2 w-100 ${isMobileView ? 'flex-column' : 'flex-row'}`">
          <div
            v-for="(itinerary, itineraryIndex) in tourSelected?.itineraries"
            :key="itineraryIndex"
            :class="`d-flex-between gap-2 ${isMobileView ? 'flex-column' : 'flex-row'}`"
          >
            <TourShortenFlightTrip
              v-for="(segment, segmentIndex) in itinerary"
              :key="segmentIndex"
              :segment="segment"
              type="block"
              :date-type="itineraryIndex === (tourSelected?.itineraries.length - 1) ? 'arrival' : 'departure'"
            />
            <div
              v-if="itineraryIndex === 0 && !isMobileView"
              class="divider-line"
            />
          </div>
        </div>

        <div
          v-if="showInfo"
          class="top-border"
        />
        <TourDetailInfo
          v-if="showInfo"
          :tourSelected="tourSelected"
          :customClass="`${isMobileView ? 'd-flex flex-column gap-1' : 'd-grid grid-cols-2 gap-2'} my-50 w-100`"
        />
      </Stack>
    </Stack>

    <div class="top-border" />

    <Stack class="gap-2">
      <HStack
        :between="isMobileView"
        :center="!isMobileView"
      >
        <div v-if="isMobileView" />
        <div :class="`text-center text-tour ${isMobileView ? 'text-16px' : 'text-24px'} fw-700`">
          Giá tour
        </div>
        <BButton
          v-if="isMobileView"
          variant="flat-success"
          class="p-50 rounded-circle"
          @click="toggleShowCommission"
        >
          <IAmIcon
            :icon="isShowCommission ? 'moneyOutline' : 'notMoneyOutline'"
            size="18"
          />
        </BButton>
      </HStack>
      <div :class="`d-flex-between gap-2 ${isMobileView ? 'flex-column' : 'flex-row'}`">
        <Stack class="flex-1 gap-2 w-100">
          <!-- ANCHOR: Người lớn -->
          <div class="d-flex-between gap-2">
            <Stack class="py-50 gap-0">
              <div class="fw-700">
                {{ $t('tour.passenger.Adults') }}:
              </div>
              <span
                style="font-weight: 400"
                class="font-italic text-12px"
              >
                ({{ $t('tour.passenger.ageFrom', { ageFrom: tourSelected.agesAdults }) }})
              </span>
            </Stack>
            <Stack
              end
              gap="0"
            >
              <div :class="`text-danger ${isMobileView ? 'text-16px' : 'text-20px'} fw-700`">
                {{ formatCurrency(tourSelected?.adultsPriceSales) }}
              </div>
              <span
                v-if="isShowCommission"
                class="font-italic text-12px"
              >
                {{ $t('tour.field.commission') }}:
                <span class="fw-600 text-success">
                  {{ formatCurrency(tourSelected?.adultsAgencyPromotion ?? 0) }}
                </span>
              </span>
            </Stack>
          </div>

          <!-- ANCHOR: Trẻ em -->
          <div class="d-flex-between gap-2">
            <Stack class="py-50 gap-0">
              <div class="fw-700">
                {{ $t('tour.passenger.Children1') }}:
              </div>
              <span
                style="font-weight: 400"
                class="font-italic text-12px"
              >
                ({{ $t('tour.passenger.ageBetween', { ageFrom: tourSelected.agesChildren1From, ageTo: tourSelected.agesChildren1To }) }})
              </span>
            </Stack>
            <Stack
              end
              gap="0"
            >
              <div :class="`text-danger ${isMobileView ? 'text-16px' : 'text-20px'} fw-700`">
                {{ formatCurrency(tourSelected?.childrenHasBedPriceSales) }}
              </div>
              <span
                v-if="isShowCommission"
                class="font-italic text-12px"
              >
                {{ $t('tour.field.commission') }}:
                <span class="fw-600 text-success">
                  {{ formatCurrency(tourSelected?.children1AgencyPromotion ?? 0) }}
                </span>
              </span>
            </Stack>
          </div>
        </Stack>
        <div
          v-if="!isMobileView"
          class="divider-line"
        />
        <Stack class="flex-1 gap-2 w-100">
          <!-- ANCHOR Trẻ em 2 (ngủ ghép) -->
          <div class="d-flex-between gap-2">
            <Stack class="py-50 gap-0">
              <div class="fw-700">
                {{ $t('tour.passenger.Children2') }}:
              </div>
              <span
                style="font-weight: 400"
                class="font-italic text-12px"
              >
                ({{ $t('tour.passenger.ageBetween', { ageFrom: tourSelected.agesChildren2From || tourSelected.agesChildren1From, ageTo: tourSelected.agesChildren2To || tourSelected.agesChildren1To }) }})
              </span>
            </Stack>
            <Stack
              end
              gap="0"
            >
              <div :class="`text-danger ${isMobileView ? 'text-16px' : 'text-20px'} fw-700`">
                {{ formatCurrency(tourSelected?.childrenNoBedPriceSales) }}
              </div>
              <span
                v-if="isShowCommission"
                class="font-italic text-12px"
              >
                {{ $t('tour.field.commission') }}:
                <span class="fw-600 text-success">
                  {{ formatCurrency(tourSelected?.children2AgencyPromotion ?? 0) }}
                </span>
              </span>
            </Stack>
          </div>

          <div class="d-flex-between gap-2">
            <Stack class="py-50 gap-0">
              <div class="fw-700">
                {{ $t('tour.passenger.Infant') }}:
              </div>
              <span
                style="font-weight: 400"
                class="font-italic text-12px"
              >
                ({{ $t('tour.passenger.ageTo', { ageTo: tourSelected.agesInfant }) }})
              </span>
            </Stack>
            <Stack
              end
              gap="0"
            >
              <div :class="`text-danger ${isMobileView ? 'text-16px' : 'text-20px'} fw-700`">
                {{ formatCurrency(tourSelected?.infantPriceSales) }}
              </div>
              <span
                v-if="isShowCommission"
                class="font-italic text-12px"
              >
                {{ $t('tour.field.commission') }}:
                <span class="fw-600 text-success">
                  {{ formatCurrency(tourSelected?.infantAgencyPromotion ?? 0) }}
                </span>
              </span>
            </Stack>
          </div>
        </Stack>
      </div>
    </Stack>

    <div class="d-flex-center mt-1">
      <BAlert
        variant="success"
        show
        class="px-1 py-50 fit-content rounded-pill fw-600 mb-0 text-center"
      >
        Chưa bao gồm tiền phí dịch vụ 100.000 vnđ/ ngày/ khách.
      </BAlert>
    </div>
  </BCard>
</template>
<script>
import { BAlert, BButton, BCard } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

import { formatCurrency } from '@/@core/utils/filter'
import store from '@/store'

export default {
  components: {
    BCard,
    BAlert,
    BButton,
    TourShortenFlightTrip: () => import('./TourShortenFlightTrip.vue'),
    TourDetailInfo: () => import('./TourDetailInfo.vue'),
  },
  props: {
    tourSelected: {
      type: Object,
      default: () => ({}),
    },
    showInfo: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Lịch trình',
    },
  },
  setup() {
    const isShowCommission = computed(() => store.getters['app-tour/getShowCommission'])

    function toggleShowCommission() {
      store.dispatch('app-tour/setShowCommission', !isShowCommission.value)
    }
    return {
      formatCurrency,
      isShowCommission,
      toggleShowCommission,
    }
  },
}
</script>
<style lang="scss" scoped>
  .top-border {
    margin: 12px auto;
    border-top: 2px solid #e6e6e6;
    width: 95%;
  }

  .divider-line {
    border-left: 1px solid #b9b9b9;
    height: 100px;
    margin: 0 12px;
  }

  .d-grid {
    display: grid;
  }

  .grid-cols-2 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content;
  }
</style>
